<template>
  <div class="withdraw">
    <common-header title="提现" />
    <div class="content">
      <div class="coin">
        <h4 class="coin-title">币种</h4>
        <p class="coin-name">
          <img src="../../assets/images/fil.png" alt="" />
          FIL
        </p>
      </div>
      <div class="input">
        <input
          type="number"
          name="myAddress"
          placeholder="请输入提现数量"
          class="my-address-input"
          v-model="withdrawAmount"
        />
        <div class="all">全部</div>
      </div>
      <div class="info">
        <p>最小提现 {{ withdrawInfo.min }}FIL</p>
        <p>手续费 {{ withdrawInfo.fee }}FIL</p>
        <p>地址 {{ this.getAddress }}</p>
        <p>
          余额 <span> {{ balance }} FIL </span>
        </p>
      </div>
    </div>
    <button @click="onWithdraw" class="sub-btn">提现</button>
    <div class="withdraw-table">
      <h4>明细</h4>
      <van-list
        class="withdraw-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="table-title">
          <p class="time">时间</p>
          <p class="count">FIL</p>
          <p class="status">状态</p>
        </div>
        <div
          class="table-title-item"
          v-for="item in withdrawRecordList"
          :key="item._id"
        >
          <p class="time">{{ item.createdAt }}</p>
          <p class="count">{{ item.amount }}</p>
          <p :class="['status', item.progress ? 'on-chain' : 'success']">
            {{ status[item.progress] }}
          </p>
        </div>
      </van-list>
    </div>
    <verify-code
      :visiable="visiable"
      @close="handleClose"
      @click="handleSubmit"
      @refersh="getVerifyCodeImg"
      v-model.trim="verifyCode"
      :verifyImg="verifyCodeImg"
    />
  </div>
</template>

<script>
import CommonHeader from "../../components/CommonHeader.vue";
import {
  getBalance,
  getVerifyCode,
  getWithdrawFee,
  withdraw,
  withdrawRecord,
} from "../../server";
import _ from "lodash";
// import NoData from "../../components/NoData.vue";
import VerifyCode from "../../components/VerifyCode";
import * as timeago from "timeago.js";

export default {
  components: { CommonHeader, VerifyCode },
  name: "Withdraw",
  data() {
    return {
      withdrawInfo: {
        min: 0,
        fee: 0,
      },
      balance: 0,
      withdrawAmount: "",
      visiable: false,
      verifyCodeImg: "",
      verifyCode: "",
      current: 1,
      withdrawRecordList: [],
      status: {
        1: "正在上链",
        2: "成功",
        3: "驳回",
      },
      loading: false,
      finished: true,
    };
  },
  methods: {
    async onLoad() {
      this.current = this.current + 1;
      const data = await withdrawRecord(this.getAddress, this.current, "FIL");
      const arr = [...this.withdrawRecordList, ...data.list];
      this.loading = false;
      if (this.withdrawRecordList.length === arr.length) {
        this.finished = true;
      }
    },
    onWithdraw() {
      if (!this.withdrawAmount) return this.$toast("请输入提现数量");
      if (this.withdrawAmount < this.withdrawInfo.min)
        return this.$toast(`最少提现 ${this.withdrawInfo.min} FIL`);
      if (this.withdrawAmount > this.balance) return this.$toast("余额不足");
      this.visiable = true;
    },
    async handleSubmit() {
      if (!this.verifyCode) return this.$toast("请输入验证码");

      this.visiable = false;
      await withdraw(
        this.getAddress,
        "FIL",
        this.withdrawAmount,
        this.verifyCode
      );
      await this.getFBalance();
      await this.getWithdrawRecord();
    },
    handleClose() {
      this.visiable = false;
    },
    getVerifyCodeImg: _.throttle(async function () {
      this.verifyCodeImg = await getVerifyCode(this.getAddress, 2);
    }, 2000),
    async getFBalance() {
      const balanceInfo = await getBalance(this.getAddress, "FIL");
      this.balance = balanceInfo.available;
    },
    async getWithdrawRecord() {
      const data = await withdrawRecord(this.getAddress, this.current, "FIL");
      this.withdrawRecordList = data.list.map((item) => {
        return {
          ...item,
          createdAt: timeago.format(item.createdAt, "zh_CN"),
        };
      });
    },
  },
  async created() {
    this.withdrawInfo = await getWithdrawFee("FIL");
    await this.getFBalance();
    await this.getVerifyCodeImg();
    await this.getWithdrawRecord();
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  width: 100%;
  height: 100%;

  .content {
    width: 345px;
    height: 257px;
    background: #161f2c;
    opacity: 1;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 15px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    font-family: "PingFang SC";
    color: #e9ecf0;

    .coin {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .coin-title {
        font-size: 14px;
        font-weight: 400;
      }

      .coin-name {
        display: flex;
        align-items: center;
        width: 96px;
        height: 30px;
        background: #0f151f;
        opacity: 1;
        border-radius: 5px;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
    }

    .input {
      width: 315px;
      height: 40px;
      background: #0f151f;
      opacity: 1;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .my-address-input {
        width: auto;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        color: #a2a2a2;
        background-color: transparent;
        text-align: center;
      }

      .all {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        width: 41px;
        height: 20px;
        border: 1px solid #019969;
        opacity: 1;
        border-radius: 5px;
        font-size: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 20px;
        color: #019969;
      }
    }

    .info {
      height: 99px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-size: 12px;
        font-weight: 500;

        span {
          color: #019969;
        }
      }
    }
  }

  .withdraw-table {
    width: 345px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h4 {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #e9ecf0;
    }

    .withdraw-list {
      margin-bottom: 30px;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      width: 345px;
      height: 306px;
      background: #161f2c;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow-y: scroll;

      .table-title,
      .table-title-item {
        flex-shrink: 0;
        width: 100%;
        height: 37px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #263243;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 400;
        color: #c1c1c1;

        .time {
          width: 134px;
        }

        .count {
          width: 133px;
        }

        .status {
          flex-grow: 1;
        }
      }

      .table-title-item {
        .status {
          color: #5399fd;
        }

        .success {
          color: #019969;
        }
      }
    }
  }

  .sub-btn {
    display: block;
    width: 345px;
    height: 45px;
    background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
    box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
    opacity: 1;
    border-radius: 21px;
    font-size: 18px;
    font-family: "PingFang SC";
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    color: #e9ecf0;
    border: none;
    margin: 0 auto;
    margin-top: 15px;
  }

  .dialog-pwd {
    .pay-pwd-input {
      display: block;
      border: none;
      width: 265px;
      height: 34px;
      background: #f4f4f4;
      opacity: 1;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 15px;
      margin-bottom: 6px;
      box-sizing: border-box;
      padding-left: 10px;
    }

    .pay-pwd-input::placeholder {
      color: #b5b5b5;
    }

    .forget-pwd {
      display: block;
      width: 100%;
      text-align: right;
      box-sizing: border-box;
      padding-right: 15px;
      padding-bottom: 10px;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #0291ff;
    }
  }
}
</style>
